import red from '@material-ui/core/colors/red';
export const FAVORITES = 1;
export const CART = 2;
export const PAYMENT = 3;
export const COLLECTION = 0;

//ROLES
export const ADMIN_ROLE = '0';
export const COMMERCIAL_ROLE = '1';
export const CLIENT_ROLE = '2';

// PAYMENT METHODS
export const CREDIT_CARD = 0;
export const TRANSFER = 1;

// IMAGES
// Login
export const LOGIN_BACKGROUND = '/icons/sketchviewer_login_background.png';
export const BACKGROUND_LIGHT = '/icons/SKV_background_light.png';
export const LOGIN_CADT_LOGO = '/icons/cadt-logo.png';
//Empty Screen
export const EMPTY_SCREEN_IMG = '/icons/leaves.png';

// TUTORIAL
export const TUTORIAL_IMAGES = [
    '/tutorial/00.png',
    '/tutorial/01.png',
    '/tutorial/02.png',
    '/tutorial/03.png',
    '/tutorial/04.png',
    '/tutorial/05.png',
    '/tutorial/06.png',
    '/tutorial/07.png',
    '/tutorial/08.png',
    '/tutorial/09.png',
    '/tutorial/10.png',
    '/tutorial/11.png',
    '/tutorial/12.png',
    '/tutorial/13.png',
    '/tutorial/14.png',
    '/tutorial/15.png',
    '/tutorial/16.png',
    '/tutorial/17.png',
    '/tutorial/18.png',
    '/tutorial/19.png',
    '/tutorial/20.png',
    '/tutorial/21.png',
    '/tutorial/22.png',
    '/tutorial/23.png',
    '/tutorial/24.png',
    '/tutorial/25.png',
    '/tutorial/26.png',
    '/tutorial/27.png',
    '/tutorial/28.png',
    '/tutorial/29.png',
];

//WATERMARK IMAGE
export const NOT_AVAILABLE_WATERMARK = '/icons/not_available_watermark.png';
export const NOT_AVAILABLE_WATERMARK_WIDTH = 594;
export const NOT_AVAILABLE = '/icons/not_available.png';
export const NOT_AVAILABLE_QUESTION = '/icons/question_tooltip.png';
export const CADT_WATERMARK = '/icons/cadt_watermark.png';

//TERMS OF SERVICE
export const COPY_ICON = '/icons/copy.png';
export const PHOTO_NOT_ALLOWED = '/icons/photo.png';
export const PRINT_NOT_ALLOWED = '/icons/print.png';
export const SEND_ICON = '/icons/send.png';
export const SCREEN_ICON = '/icons/screen.png';
export const VIDEO_ICON = '/icons/video.png';
export const EXCHANGE_ICON = '/icons/images_for_money.png';
export const SHOPPING_PROCESS_ICON = '/icons/shop_and_pay.png';
export const SALE_TIMER_ICON = '/icons/buy_in_time.png';

export const NOT_ALLOWED_IMAGES = [
    COPY_ICON,
    PHOTO_NOT_ALLOWED,
    VIDEO_ICON,
    SCREEN_ICON,
    PRINT_NOT_ALLOWED,
    SEND_ICON,
];

export const DESCRIPTION_IMAGES = [
    {
        icon: EXCHANGE_ICON,
        text: 'TR_TERMS_TEXT_3',
    },
    {
        icon: SHOPPING_PROCESS_ICON,
        text: 'TR_TERMS_TEXT_4',
    },
    {
        icon: SALE_TIMER_ICON,
        text: 'TR_TERMS_TEXT_5',
    },
];

//MINIATURE ICONS
export const MINIATURE_FAVORITE = '/icons/added_favorites.png';
export const MINIATURE_SHOPPING_CART = '/icons/added_shopping_cart.png';

export const ADD_PHOTO_COMMENT = '/icons/add_photo_comment.svg';
export const FILE_ICON = '/icons/file.svg';

//ENDPOINTS
export const CREATE_USER_ENDPOINT = 'create_user';
export const VALIDATE_RECAPTCHA_ENDPOINT = 'validate_recaptcha';
export const USER_SESSION_ENDPOINT = 'user_session';
export const CLOSE_SESSION_ENDPOINT = 'close_session';
export const HISTORY_LOG_ENDPOINT = 'close_history_log';
export const PAYMENT_INTENT_ENDPOINT = 'create-payment-intent';

export const CLIENT_ID = '88152814-0071-11e6-a247-000ec6c2374';
export const RECAPTCHA_KEY = '6Ld6jvwUAAAAABDT7-Fxqn6hZDp3ISRfYKCBu1cz';

//ROUTES
export const CART_URL = '/cart';
export const FAVORITE_URL = '/favorites';
export const COLLECTION_URL = '/collection';
export const SIGN_UP_URL = '/signup';
export const SALES_URL = '/sales';
export const PAYMENT_URL = '/payment';
//COLORS
export const RED_COLOR = '#E21F04';
export const LIGHT_GREY_COLOR = '#8080805e';
export const GREY_COLOR = '#685f5fb3';
export const FAVORITE_COLOR = red[900];
export const LIGHTER_GREY_COLOR = '#D7D7D7';
export const CREAM_COLOR = '#fcf3cb';
export const YELLOW_COLOR = '#fed700';
export const BROKEN_WHITE = '#f7faf9';

//DESCRIPTIONS AND TEXT TO TRANSLATE
export const NOT_AVAILABLE_TOOLTIP =
    'This sketch is temporarily out of the collection because there is another customer interested in it right now.';

//TEST CONSTANTS
export const SELENIUM_URL = 'https://sketches.cadt.com';

//LOGS
export const START_LOGGING_THRESHOLD = 3; // seconds

//LOCALSTORAGE
export const KEY_TOKEN = 'id_token';
export const KEY_ROLE = 'role';
export const KEY_EMAIL = 'email';
export const KEY_TUTORIAL_DISABLED = 'tutorial_disabled';
export const CURRENT_SALE_KEY = 'current-sale';
export const KEY_PASSWORD_RESET = 'password_reset';

//KEYS
export const KEY_DOWN = 40;
export const KEY_UP = 38;
export const KEY_LEFT = 37;
export const KEY_RIGHT = 39;

//FILTER
export const N_A = 'N/A';
export const ASC = 'ASC';
export const DESC = 'DESC';

//HARD-CODED-DISCOUNT

/* export const GLOBAL_DISCOUNT = 0.25; */
/* Black Friday - 20231123 */
/* export const GLOBAL_DISCOUNT = 0.30; */
export const GLOBAL_DISCOUNT = 0.0;
