export const APP_PREFIX = 'sketch-viewer';

export function localStorageGet(key) {
    const prefixKey = `${APP_PREFIX}.${key}`;
    const value = localStorage.getItem(prefixKey);
    return value;
}

export function localStorageSet(key, value) {
    const prefixKey = `${APP_PREFIX}.${key}`;
    localStorage.setItem(prefixKey, value);
}

export function localStorageRemove(key) {
    const prefixKey = `${APP_PREFIX}.${key}`;
    localStorage.removeItem(prefixKey);
}
